import React from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Inspector } from 'react-dev-inspector';

const InspectorWrapper =
  process.env.NODE_ENV === 'development' ? Inspector : React.Fragment;

moment.locale('zh-cn');

export function rootContainer(container: any) {
  return (
    <InspectorWrapper keys={['shift', 'x']} disableLaunchEditor={false}>
      {container}
    </InspectorWrapper>
  );
}
