import { history } from 'umi';
import { message } from 'antd';
import { ConfigProvider } from '@parallel-line/mobile';
import { global, getPageQuery } from '@parallel-line/utils';
import flex from '@/utils/flex';

const isTest = process.env.PXX_ENV === 'test';
const isDev = process.env.PXX_ENV === 'dev';

// 启用vconsole移动端打印工具
if (getPageQuery()?.hasOwnProperty?.('vconsole')) {
  import('@/utils/vconsole');
}

declare global {
  interface Window {
    alitaFontScale: number;
    VConsole?: any;
    vConsole?: any;
    global?: any;
  }

  var wx: any;
}

if (typeof document !== 'undefined') {
  const getViewPort = (str?: string): any => {
    if (!str) return {};
    const arr = str.split(',');
    const hashArr: any = {};
    arr.forEach((s) => {
      const ss = s.split('=');
      hashArr[ss[0].replace(/(^\s*)|(\s*$)/g, '')] = ss[1];
    });
    return hashArr;
  };

  // 自动处理页面使用 iframe 嵌套缩放问题
  if (window != top && !window.alitaFontScale) {
    // window.alitaFontScale = 0.5;
    const meta: any = document.querySelector('meta[name="viewport"]');
    const metaStr = meta.getAttribute('content') || '';
    const viewport = getViewPort(metaStr);
    if (viewport['initial-scale']) {
      const dpr = window.devicePixelRatio || 1;
      const baseScale = 10 / dpr;
      window.alitaFontScale =
        baseScale /
        parseInt(`${parseFloat(viewport['initial-scale']) * 10}`, 10);
    }
  }
  // 感觉全部用 flex 就很棒，嵌套页面缩放问题，直接设置 alitaFontScale
  flex(100, window.alitaFontScale || 1);
  // hd solution for antd-mobile@2
  // ref: https://mobile.ant.design/docs/react/upgrade-notes-cn#%E9%AB%98%E6%B8%85%E6%96%B9%E6%A1%88
  document.documentElement.setAttribute('data-scale', true as any);
}

function initVConsole(query?: any) {
  const { isVConsole } = query || {};
  if (isVConsole) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = function () {
      window.vConsole = new window.VConsole();
    };
    script.src = 'https://unpkg.com/vconsole/dist/vconsole.min.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

initVConsole(history.location.query);
history.listen((location) => {
  initVConsole(location.query);
});
global.wx = {};
global.g_history = history;
global.ConfigProvider = ConfigProvider;

global.message = {
  info(msg) {
    message.info(msg);
  },
  success(msg) {
    message.success(msg);
  },
  error(msg) {
    message.error(msg);
  },
  warn(msg) {
    message.warn(msg);
  },
};

global.history = {
  push: history.push.bind(history),
  replace(arg1: any, arg2?: any) {
    if (arg1 === '/login' && global.PXX_ENV.isDriver) {
      if (global.PXX_ENV.isWeapp) {
        return wx.miniProgram.reLaunch({
          url: '/pages/login/wechat/index?action=logout',
        });
      }
      return history.replace('/driver/login/phone.html');
    }
    if (global.PXX_ENV.isWorker) {
      return history.replace('/worker/login/phone.html');
    }
    return history.replace(arg1, arg2);
  },
  reload() {
    window.location.reload();
  },
};
console.log('init', global.PXX_ENV);
global.PXX_ENV = {
  ...global.PXX_ENV,
  isDev,
  isTest,
  isH5: true,
  isDevTest: isDev || isTest,
  isDriver: process.env.PLATFORM === 'driver',
  isWorker: process.env.PLATFORM === 'worker',
};
// Object.freeze(global.PXX_ENV);

const PXX_USER_STATE = new Proxy(
  { isLogin: false, isAutentication: false },
  {
    get(target: any, key) {
      if (key === 'isLogin') {
        return !!localStorage.getItem('pxx_accessToken');
      }
      return target[key];
    },
  },
);

global.PXX_USER_STATE = PXX_USER_STATE;
global.assetsUrl = 'https://cdn.pxxtech.com/assets';
window.global = global;
