import { useInterval } from 'ahooks';
import React, { useEffect, useState } from 'react';
import PxxLottie from './PxxLottie';
import { Result } from 'antd';
import Button from '../button';

import logo from './logo.svg';
import styles from './index.less';

const PageLoading: React.FC = () => {
  const [count, setCount] = useState(30);
  const [interval, setInterval] = useState<number | null>(1000);

  useInterval(() => {
    setCount(count - 1);
    if (count === 0) {
      // 清除定时
      setInterval(null);
    }
  }, interval);

  useEffect(
    () => () => {
      setInterval(null);
    },
    [],
  );

  return (
    <div className={styles.PageLoading}>
      {count <= 0 ? (
        <Result
          status="warning"
          title="文件加载遇到问题"
          extra={
            <Button type="primary" onClick={() => window.location.reload()}>
              刷新一下
            </Button>
          }
        />
      ) : (
        <div className={styles.logo}>
          <PxxLottie />
        </div>
      )}
    </div>
  );
};
export default PageLoading;
