import * as React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from 'antd';

import styles from './index.less';

export default ({
  className,
  ...props
}: React.PropsWithChildren<ButtonProps>) => (
  <Button className={classNames(styles.Button, className)} {...props} />
);
