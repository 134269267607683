// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/data/jenkins/workspace/prod-front-h5/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/baseComponents/page-loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/driver(.html)?",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__driver' */'@/layouts/driver'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/driver/tab(.html)?",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__tab-pages' */'@/pages-driver/tab-pages'), loading: LoadingComponent}),
        "routes": [
          {
            "title": "首页",
            "path": "/driver/tab/home.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__tab-pages__home' */'@/pages-driver/tab-pages/home'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "运单",
            "path": "/driver/tab/order.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__tab-pages__order' */'@/pages-driver/tab-pages/order'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "我的",
            "path": "/driver/tab/personal.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__tab-pages__personal' */'@/pages-driver/tab-pages/personal'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "title": "完税凭证",
        "path": "/driver/taxEvidence.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__taxEvidence' */'@/pages/taxEvidence'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/driver/taskOver.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__taskOver' */'@/pages-driver/taskOver'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/driver/order/transport.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__transport' */'@/pages-driver/transport'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/driver/helperDetail.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__helperDetail' */'@/pages-driver/helperDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/driver/order/site.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__site' */'@/pages-driver/site'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/driver/login/phone",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/driver/login/phone",
        "exact": true
      },
      {
        "title": "登录",
        "path": "/driver/login/phone.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__phone' */'@/pages/login/phone'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "登录",
        "path": "/driver/login/code.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__code' */'@/pages/login/code'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "设置",
        "path": "/driver/settings.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__settings' */'@/pages-driver/settings'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "咨询与投诉",
        "path": "/driver/consultation.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__consultation' */'@/pages-driver/consultation'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/driver/wallet(.html)?",
        "routes": [
          {
            "path": "/driver/wallet/overview.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__wallet' */'@/pages-driver/wallet'), loading: LoadingComponent}),
            "title": "钱包",
            "exact": true
          },
          {
            "path": "/driver/wallet/income.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__wallet__income' */'@/pages-driver/wallet/income'), loading: LoadingComponent}),
            "title": "我的月收入",
            "exact": true
          },
          {
            "path": "/driver/wallet/incomeDetail.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__wallet__income__detail' */'@/pages-driver/wallet/income/detail'), loading: LoadingComponent}),
            "title": "收入详情",
            "exact": true
          },
          {
            "path": "/driver/wallet/bankCardsList.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__wallet__bankCardsList' */'@/pages-driver/wallet/bankCardsList'), loading: LoadingComponent}),
            "title": "银行卡",
            "exact": true
          },
          {
            "path": "/driver/wallet/addBankCard.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__wallet__addBankCard' */'@/pages-driver/wallet/addBankCard'), loading: LoadingComponent}),
            "title": "添加银行卡",
            "exact": true
          }
        ]
      },
      {
        "title": "运单详情",
        "path": "/driver/order/detail.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__order__detail' */'@/pages-driver/order/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "上传回单",
        "path": "/driver/order/receipt.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__order__receipt' */'@/pages-driver/order/receipt'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/driver/vehicle(.html)?",
        "routes": [
          {
            "path": "/driver/vehicle/list.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle' */'@/pages-driver/vehicle'), loading: LoadingComponent}),
            "title": "我的车辆",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add' */'@/pages-driver/vehicle/add'), loading: LoadingComponent}),
            "title": "选择车辆类型",
            "exact": true
          },
          {
            "path": "/driver/vehicle/look.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__look' */'@/pages-driver/vehicle/look'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          },
          {
            "path": "/driver/vehicle/result.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__result' */'@/pages-driver/vehicle/result'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add/readyTruck.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add__readyTruck' */'@/pages-driver/vehicle/add/readyTruck'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add/truck.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add__truck' */'@/pages-driver/vehicle/add/truck'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add/trailer/choose.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add__trailer__choose' */'@/pages-driver/vehicle/add/trailer/choose'), loading: LoadingComponent}),
            "title": "选择车辆类型",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add/trailer/readyHead.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add__trailer__readyHead' */'@/pages-driver/vehicle/add/trailer/readyHead'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add/trailer/head.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add__trailer__head' */'@/pages-driver/vehicle/add/trailer/head'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add/trailer/readyBody.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add__trailer__readyBody' */'@/pages-driver/vehicle/add/trailer/readyBody'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          },
          {
            "path": "/driver/vehicle/add/trailer/body.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__vehicle__add__trailer__body' */'@/pages-driver/vehicle/add/trailer/body'), loading: LoadingComponent}),
            "title": "完善车辆信息",
            "exact": true
          }
        ]
      },
      {
        "path": "/driver/authentication(.html)?",
        "routes": [
          {
            "path": "/driver/authentication/personal/list.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__authentication__personal__list' */'@/pages-driver/authentication/personal/list'), loading: LoadingComponent}),
            "title": "司机准入",
            "exact": true
          },
          {
            "path": "/driver/authentication/confirm/personal.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__confirm' */'@/pages/authentication/confirm'), loading: LoadingComponent}),
            "title": "个人信息认证",
            "exact": true
          },
          {
            "path": "/driver/authentication/information/personal.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__information' */'@/pages/authentication/information'), loading: LoadingComponent}),
            "title": "完善个人信息",
            "exact": true
          },
          {
            "path": "/driver/authentication/confirm/driverLicense.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__confirm' */'@/pages/authentication/confirm'), loading: LoadingComponent}),
            "title": "驾驶证信息认证",
            "exact": true
          },
          {
            "path": "/driver/authentication/information/driverLicense.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__information' */'@/pages/authentication/information'), loading: LoadingComponent}),
            "title": "完善驾驶证信息",
            "exact": true
          },
          {
            "path": "/driver/authentication/confirm/qualification.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__confirm' */'@/pages/authentication/confirm'), loading: LoadingComponent}),
            "title": "从业资格证信息",
            "exact": true
          },
          {
            "path": "/driver/authentication/information/qualification.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__information' */'@/pages/authentication/information'), loading: LoadingComponent}),
            "title": "完善从业资格证信息",
            "exact": true
          },
          {
            "path": "/driver/authentication/information/portrait.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__information' */'@/pages/authentication/information'), loading: LoadingComponent}),
            "title": "完善个人照片",
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "title": "帮助指引",
    "path": "/helper.html",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__helper' */'@/pages-driver/helper'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/video.html",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__video' */'@/pages/video'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "title": "平行线",
    "path": "/agreement.html",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__agreement' */'@/pages/agreement'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__initLayout' */'@/layouts/initLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/TCaptcha.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TCaptcha' */'@/pages/TCaptcha'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/download.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__download' */'@/pages/download'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/downloadAPP.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__downloadAPP' */'@/pages/downloadAPP'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/tooltip.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tooltip' */'@/pages/tooltip'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/invite(.html)?",
        "routes": [
          {
            "path": "/invite/driver.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__driver' */'@/pages/invite/driver'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/invite/vehicle.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__vehicle' */'@/pages/invite/vehicle'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/invite/enterprise.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__enterprise' */'@/pages/invite/enterprise'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/invite/request.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__request' */'@/pages/invite/request'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/invite/result.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__result' */'@/pages/invite/result'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/invite/not-applied.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite__not-applied' */'@/pages/invite/not-applied'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/invitation(.html)?",
        "routes": [
          {
            "path": "/invitation/driver.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invitation__driver' */'@/pages/invitation/driver'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/invitation/enterprise.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invitation__enterprise' */'@/pages/invitation/enterprise'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/invitation/result.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invitation__result' */'@/pages/invitation/result'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/vehicleAccess(.html)?",
        "routes": [
          {
            "path": "/vehicleAccess/login.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__vehicleAccess__login' */'@/pages/vehicleAccess/login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/vehicleAccess/search.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__vehicleAccess__search' */'@/pages/vehicleAccess/search'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/monitoring.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring' */'@/pages/monitoring'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/wx(.html)?",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__weixinWeb' */'@/layouts/weixinWeb'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/wx/bindAccount.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__bindAccount' */'@/pages-wx/bindAccount'), loading: LoadingComponent}),
            "title": "绑定账号",
            "exact": true
          },
          {
            "path": "/wx/my(.html)?",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__my' */'@/pages-wx/my'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/wx/my/order.html",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__my__order' */'@/pages-wx/my/order'), loading: LoadingComponent}),
                "title": "订单",
                "exact": true
              },
              {
                "path": "/wx/my/info.html",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__my__info' */'@/pages-wx/my/info'), loading: LoadingComponent}),
                "title": "我的",
                "exact": true
              }
            ]
          },
          {
            "path": "/wx/relationPhone.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__relationPhone' */'@/pages-wx/relationPhone'), loading: LoadingComponent}),
            "title": "关联手机号",
            "exact": true
          },
          {
            "path": "/wx/cargoDetail.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__cargoDetail' */'@/pages-wx/cargoDetail'), loading: LoadingComponent}),
            "title": "我的货物详情",
            "exact": true
          },
          {
            "path": "/wx/sign.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__sign' */'@/pages-wx/sign'), loading: LoadingComponent}),
            "title": "货物签收",
            "exact": true
          },
          {
            "path": "/wx/relationPhone.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__relationPhone' */'@/pages-wx/relationPhone'), loading: LoadingComponent}),
            "title": "关联手机号",
            "exact": true
          },
          {
            "path": "/wx/signForHistory.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-wx__signForHistory' */'@/pages-wx/signForHistory'), loading: LoadingComponent}),
            "title": "签收记录",
            "exact": true
          },
          {
            "path": "/wx/orderDetail.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring' */'@/pages/monitoring'), loading: LoadingComponent}),
            "title": "订单详情",
            "exact": true
          }
        ]
      },
      {
        "path": "/worker(.html)?",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__worker' */'@/layouts/worker'), loading: LoadingComponent}),
        "routes": [
          {
            "title": "登录",
            "path": "/worker/login/phone.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__phone' */'@/pages/login/phone'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "登录",
            "path": "/worker/login/code.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__code' */'@/pages/login/code'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/worker/tabPages(.html)?",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-worker__tabPages' */'@/pages-worker/tabPages'), loading: LoadingComponent}),
            "routes": [
              {
                "title": "订单",
                "path": "/worker/tabPages/order.html",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-worker__tabPages__order' */'@/pages-worker/tabPages/order'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "title": "我的",
                "path": "/worker/tabPages/personal.html",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-worker__tabPages__personal' */'@/pages-worker/tabPages/personal'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "title": "银行卡",
            "path": "/worker/bankCardsList.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__wallet__bankCardsList' */'@/pages-driver/wallet/bankCardsList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "信息维护",
            "path": "/worker/information.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-worker__information' */'@/pages-worker/information'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "添加银行卡",
            "path": "/worker/addBankCard.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-driver__wallet__addBankCard' */'@/pages-driver/wallet/addBankCard'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/worker/orderDetail.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-worker__orderDetail' */'@/pages-worker/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "个人信息认证",
            "path": "/worker/authentication/personal/confirm.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__confirm' */'@/pages/authentication/confirm'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "身份认证",
            "path": "/worker/authentication/personal/information.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authentication__information' */'@/pages/authentication/information'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "设置",
            "path": "/worker/setting.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-worker__settings' */'@/pages-worker/settings'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "实名认证",
            "path": "/worker/realname.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'pages-worker__realname' */'@/pages-worker/realname'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "title": "完税凭证",
            "path": "/worker/taxEvidence.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__taxEvidence' */'@/pages/taxEvidence'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
