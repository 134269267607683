import React from 'react';
import Lottie from 'lottie-react-web';
import JsonData from './lottie.json';

const PxxLottie: React.FC = () => {
  return (
    <Lottie
      options={{
        autoplay: true,
        animationData: JsonData,
        loop: true,
      }}
    />
  );
};

export default PxxLottie;
