import { calc } from '@parallel-line/utils';

const win = window;

/**
 * @param baseFontSize - 基础fontSize, 默认100px;
 * @param fontscale - 有的业务希望能放大一定比例的字体;
 */
export default (baseFontSize: number = 100, fontscale: number = 1) => {
  const _baseFontSize = baseFontSize || 100;
  const _fontscale = fontscale || 1;
  //
  // const doc = win.document;
  // const ua = navigator.userAgent;
  // const matches: any = ua.match(/Android[\S\s]+AppleWebkit\/(\d{3})/i);
  // const UCversion = ua.match(/U3\/((\d+|\.){5,})/i);
  // const isUCHd =
  //   UCversion && parseInt(UCversion[1].split('.').join(''), 10) >= 80;
  // const isIos = navigator.appVersion.match(/(iphone|ipad|ipod)/gi);
  let dpr = 1;
  // let dpr = win.devicePixelRatio || 1;
  // if (!isIos && !(matches && matches[1] > 534) && !isUCHd) {
  //   // 如果非iOS, 非Android4.3以上, 非UC内核, 就不执行高清, dpr设为1;
  //   dpr = 1;
  // }
  // const scale = 1 / dpr;
  //
  // let metaEl = doc.querySelector('meta[name="viewport"]');
  // if (!metaEl) {
  //   metaEl = doc.createElement('meta');
  //   metaEl.setAttribute('name', 'viewport');
  //   doc.head.appendChild(metaEl);
  // }
  // metaEl.setAttribute(
  //   'content',
  //   `width=device-width,user-scalable=no,initial-scale=${scale},maximum-scale=${scale},minimum-scale=${scale},viewport-fit=cover`,
  // );

  // doc.documentElement.style.fontSize = `${
  //   (_baseFontSize / 2) * dpr * _fontscale
  // }px`;
  const docEle = document.documentElement;
  function setHtmlFontSize() {
    let deviceWidth = docEle.clientWidth || window.innerWidth;

    if (calc(`${deviceWidth} / ${dpr}`) > 750) {
      deviceWidth = calc(`414*${dpr}`);
    }
    let fontSize = calc(
      `(${deviceWidth} / (750 / ${_baseFontSize})) * ${_fontscale}`,
    );

    docEle.style.fontSize = fontSize.toFixed(3) + 'px';
  }
  setHtmlFontSize();
  window.addEventListener('resize', setHtmlFontSize);
};
